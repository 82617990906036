<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
         <div class="p-8">
           <vs-button class="w-full sm:w-auto" @click="$router.push('/change-password')"> Change Password</vs-button>
         </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

  export default {

  }
</script>
